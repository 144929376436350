import { ChainId } from '@abstra-dex/sdk'
import { serializeTokens } from './tokens'
import { SerializedFarmConfig } from './types'
import { CHAIN_ID } from './networks'

const serializedTokens = serializeTokens()

const chainId = parseInt(CHAIN_ID, 10)
const { MAINNET } = ChainId

export const VLX_WAG_FARM_PID = chainId === MAINNET ? 1 : 1 // WAG-VLX (2)
export const VLX_USDT_FARM_PID = chainId === MAINNET ? 1 : 1 // BUSD-VLX (3)

const farms: SerializedFarmConfig[] =
  chainId === MAINNET
    ? [
        {
          pid: 0,
          v1pid: 0,
          lpSymbol: 'AbstraDEX',
          lpAddresses: {
            388: '0x0f67da422b8eA1ee4955fC2343954Efc60021131',
            282: '',
          },
          token: serializedTokens.cake,
          quoteToken: serializedTokens.weth,
        },
        {
          pid: 1,
          v1pid: 1,
          lpSymbol: 'ABS-wzkCRO LP',
          lpAddresses: {
            388: '0xb5c0ee760fb1759f4afffd05e0cebf26a6177a12',
            282: '',
          },
          token: serializedTokens.cake,
          quoteToken: serializedTokens.weth,
        },
        {
          pid: 2,
          v1pid: 2,
          lpSymbol: 'vETH-wzkCRO LP',
          lpAddresses: {
            388: '0x19861dfd963b4e5d6321c4bb98cf8b8d33cc7cd0',
            282: '',
          },
          token: serializedTokens.veth,
          quoteToken: serializedTokens.weth,
        },
        {
          pid: 3,
          v1pid: 3,
          lpSymbol: 'vETH-vUSD LP',
          lpAddresses: {
            388: '0xcd4527e8c9dfb8f8452b346667f8d83f3cf685b3',
            282: '',
          },
          token: serializedTokens.veth,
          quoteToken: serializedTokens.usdt,
        },
        {
          pid: 4,
          v1pid: 4,
          lpSymbol: 'vUSD-wzkCRO LP',
          lpAddresses: {
            388: '0x4f6c356511a55c0e388f2a2202c022e250c15e4d',
            282: '',
          },
          token: serializedTokens.usdt,
          quoteToken: serializedTokens.weth,
        },
        {
          pid: 5,
          v1pid: 5,
          lpSymbol: 'ETH-CRO LP',
          lpAddresses: {
            388: '0xbeffc57e6fc9f2e4771ad5f82d4b8731bf7e5199',
            282: '',
          },
          token: serializedTokens.ceth,
          quoteToken: serializedTokens.cro,
        },
        {
          pid: 6,
          v1pid: 6,
          lpSymbol: 'CRO-vUSD LP',
          lpAddresses: {
            388: '0xa51a300f58302d1a9158262a6abeb9c4c88efb6c',
            282: '',
          },
          token: serializedTokens.usdt,
          quoteToken: serializedTokens.cro,
        },
        {
          pid: 7,
          v1pid: 7,
          lpSymbol: 'CRO-wzkCRO LP',
          lpAddresses: {
            388: '0x822b47200784d8d94a2dc64b2aeed1792402443f',
            282: '',
          },
          token: serializedTokens.cro,
          quoteToken: serializedTokens.weth,
        },
        {
          pid: 8,
          v1pid: 8,
          lpSymbol: 'CRO-vUSD LP',
          lpAddresses: {
            388: '0xa51a300f58302d1a9158262a6abeb9c4c88efb6c',
            282: '',
          },
          token: serializedTokens.usdt,
          quoteToken: serializedTokens.cro,
        },
        {
          pid: 9,
          v1pid: 9,
          lpSymbol: 'CRO-wzkCRO LP',
          lpAddresses: {
            388: '0x822b47200784d8d94a2dc64b2aeed1792402443f',
            282: '',
          },
          token: serializedTokens.cro,
          quoteToken: serializedTokens.weth,
        },
        {
          pid: 10,
          v1pid: 10,
          lpSymbol: 'CRO-wzkCRO LP',
          lpAddresses: {
            388: '0x822b47200784d8d94a2dc64b2aeed1792402443f',
            282: '',
          },
          token: serializedTokens.cro,
          quoteToken: serializedTokens.weth,
        },
        {
          pid: 11,
          v1pid: 11,
          lpSymbol: 'vUSD-wzkCRO LP',
          lpAddresses: {
            388: '0x4f6c356511a55c0e388f2a2202c022e250c15e4d',
            282: '',
          },
          token: serializedTokens.usdt,
          quoteToken: serializedTokens.weth,
        },
        {
          pid: 12,
          v1pid: 12,
          lpSymbol: 'CRO-wzkCRO LP',
          lpAddresses: {
            388: '0x822b47200784d8d94a2dc64b2aeed1792402443f',
            282: '',
          },
          token: serializedTokens.cro,
          quoteToken: serializedTokens.weth,
        },
      ]
    : [
        /**
         * These 3 farms (PID 0, 251, 252) should always be at the top of the file.
         */
        // {
        //   pid: 0,
        //   v1pid: 0,
        //   lpSymbol: 'ABS',
        //   lpAddresses: {
        //     196: '',
        //     195: '0x398F92e15519B4F6F2D5cc42C17FC9ED15De53d8',
        //   },
        //   token: serializedTokens.cake,
        //   quoteToken: serializedTokens.weth,
        // },
        // {
        //   pid: 1,
        //   v1pid: 1,
        //   lpSymbol: 'ABS-ZETA LP',
        //   lpAddresses: {
        //     196: '',
        //     195: '0xfC8c6FA1d473444d160bab7146bDecc8bf1d24Eb',
        //   },
        //   token: serializedTokens.cake,
        //   quoteToken: serializedTokens.weth,
        // },
        // {
        //   pid: 2,
        //   v1pid: 2,
        //   lpSymbol: 'QS_ETH LP',
        //   lpAddresses: {
        //     196: '0xdD58B168d467EDe6AD89620355198B2B81305041',
        //     195: '',
        //   },
        //   token: serializedTokens.cake,
        //   quoteToken: serializedTokens.weth,
        // },
        // {
        //   pid: 2,
        //   v1pid: 2,
        //   lpSymbol: 'VLX_TE9 LP',
        //   lpAddresses: {
        //     196: '0x40d4fC460eF4C76a20CE1BaEAEB6b921050f483f',
        //     195: '',
        //   },
        //   token: serializedTokens.te9,
        //   quoteToken: serializedTokens.wvlx,
        // },
        // {
        //   pid: 3,
        //   v1pid: 3,
        //   lpSymbol: 'VLX_TE12 LP',
        //   lpAddresses: {
        //     196: '0xc40969848d5B549138f0b1B499a69fA9B510407d',
        //     195: '',
        //   },
        //   token: serializedTokens.te12,
        //   quoteToken: serializedTokens.wvlx,
        // },
        // {
        //   pid: 4,
        //   v1pid: 4,
        //   lpSymbol: 'VLX_TE18 LP',
        //   lpAddresses: {
        //     196: '0xf01D3d7827211626E7b76DD583EaC8b369d046b0',
        //     195: '',
        //   },
        //   token: serializedTokens.te18,
        //   quoteToken: serializedTokens.wvlx,
        // },
        // {
        //   pid: 5,
        //   v1pid: 5,
        //   lpSymbol: 'VLX_ETH LP',
        //   lpAddresses: {
        //     196: '0x8A70d2a3e2cba2CAD61FbA419E62eB821F18Bb57',
        //     195: '',
        //   },
        //   token: serializedTokens.weth,
        //   quoteToken: serializedTokens.wvlx,
        // },
        // {
        //   pid: 6,
        //   v1pid: 6,
        //   lpSymbol: 'VLX_USDT LP',
        //   lpAddresses: {
        //     196: '0xF20c93c5e5F534C9D95567497Ea17a841164d37b',
        //     195: '',
        //   },
        //   token: serializedTokens.usdt,
        //   quoteToken: serializedTokens.wvlx,
        // },
        // {
        //   pid: 7,
        //   v1pid: 7,
        //   lpSymbol: 'VLX_USDC LP',
        //   lpAddresses: {
        //     196: '0x33ea93e391aF9cAA4b8e9C3368236B93DFCF39C4',
        //     195: '',
        //   },
        //   token: serializedTokens.usdc,
        //   quoteToken: serializedTokens.wvlx,
        // },
        // {
        //   pid: 8,
        //   v1pid: 8,
        //   lpSymbol: 'VLX_BUSD LP',
        //   lpAddresses: {
        //     196: '0xe25107384e3d23403c4537967D34cCe02A2b56c6',
        //     195: '',
        //   },
        //   token: serializedTokens.busd,
        //   quoteToken: serializedTokens.wvlx,
        // },
        // {
        //   pid: 9,
        //   v1pid: 9,
        //   lpSymbol: 'VLX_WAG LP',
        //   lpAddresses: {
        //     196: '0xdC415f9c745a28893b0Cbb6A8eaC1bb6ed42C581',
        //     195: '',
        //   },
        //   token: serializedTokens.cake,
        //   quoteToken: serializedTokens.wvlx,
        // },
      ]

export default farms
