import React from 'react'
import styled from 'styled-components'
import Flex from '../../components/Box/Flex'
import { Box } from '../../components/Box'
import { ArrowBackIcon, CloseIcon } from '../../components/Svg'
import { IconButton } from '../../components/Button'
import { ModalProps } from './types'

export const ModalHeader = styled.div<{ background?: string }>`
  align-items: center;
  display: flex;
  padding: 12px 24px;
`

export const ModalTitle = styled(Flex)`
  align-items: center;
  flex: 1;
  color: ${({ theme }) => theme.colors.absMainColor};
`

export const ModalBody = styled(Flex)`
  flex-direction: column;
  max-height: 90vh;
  overflow-y: auto;
  padding: 12px 24px;
  margin-bottom: 24px;
`

export const ModalCloseButton: React.FC<{ onDismiss: ModalProps['onDismiss'] }> = ({ onDismiss }) => {
  return (
    <IconButton style={{ width: 'fit-content' }} variant="text" onClick={onDismiss} aria-label="Close the dialog">
      <CloseIcon color="absBlackColor" />
    </IconButton>
  )
}

export const ModalBackButton: React.FC<{ onBack: ModalProps['onBack'] }> = ({ onBack }) => {
  return (
    <IconButton style={{ width: 'fit-content' }} variant="text" onClick={onBack} area-label="go back" mr="8px">
      <ArrowBackIcon color="absBlackColor" />
    </IconButton>
  )
}

export const ModalContainer = styled(Box)`
  overflow: hidden;
  box-shadow: 0px 20px 36px -8px rgba(14, 14, 44, 0.1), 0px 1px 1px rgba(0, 0, 0, 0.05);
  width: 100%;
  z-index: ${({ theme }) => theme.zIndices.modal};
  border-radius: 16px;

  background: ${({ theme }) => theme.colors.absModalBg};
  border: 0;
  max-width: 482px;
`
