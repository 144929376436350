import React from 'react'
import {
  MenuItemsType,
  // DropdownMenuItemType,
  // SwapIcon,
  // SwapFillIcon,
  // EarnFillIcon,
  // EarnIcon,
  // TrophyIcon,
  // TrophyFillIcon,
  // NftIcon,
  // NftFillIcon,
  // MoreIcon,
  // TradeIcon,
  // FarmIcon,
} from 'packages/uikit'
import { ContextApi } from 'contexts/Localization/types'
// import { nftsBaseUrl } from 'views/Nft/market/constants'
// import { perpLangMap } from 'utils/getPerpetualLanguageCode'
import { DropdownMenuItems } from 'packages/uikit/src/components/DropdownMenu/types'
// import {
//   HomePageIcon,
//   SwapPageIcon,
//   LiquidityPageIcon,
//   StakingPageIcon,
//   FarmingPageIcon,
//   LaunchpadPageIcon,
// } from 'components/abstradex/components/svgs'

export type ConfigMenuDropDownItemsType = DropdownMenuItems & { hideSubNav?: boolean }
export type ConfigMenuItemsType = Omit<MenuItemsType, 'items'> & { hideSubNav?: boolean } & {
  items?: ConfigMenuDropDownItemsType[]
}

const config: (t: ContextApi['t'], languageCode?: string) => ConfigMenuItemsType[] = (t, languageCode) => [
  // {
  //   label: t('Trade'),
  //   icon: SwapIcon,
  //   fillIcon: SwapFillIcon,
  //   href: '/swap',
  //   showItemsOnMobile: false,
  //   items: [
  //     {
  //       label: t('Swap'),
  //       href: '/swap',
  //     },
  //     // {
  //     //   label: t('Limit'),
  //     //   href: '/limit-orders',
  //     // },
  //     {
  //       label: t('Liquidity'),
  //       href: '/liquidity',
  //     },
  //     // {
  //     //   label: t('Perpetual'),
  //     //   href: `https://perp.pancakeswap.finance/${perpLangMap(languageCode)}/futures/BTCUSDT`,
  //     //   type: DropdownMenuItemType.EXTERNAL_LINK,
  //     // },
  //   ],
  // },
  // {
  //   label: t('Swap'),
  //   href: '/swap',
  //   icon: EarnIcon,
  //   fillIcon: EarnFillIcon,
  //   showItemsOnMobile: false,
  //   items: [
  //     {
  //       label: t('Swap'),
  //       href: '/Swap',
  //     },
  //   ],
  // },
  // {
  //   label: t('Liquidity'),
  //   href: '/liquidity',
  //   icon: EarnIcon,
  //   fillIcon: EarnFillIcon,
  //   showItemsOnMobile: false,
  //   items: [
  //     {
  //       label: t('Liquidity'),
  //       href: '/liquidity',
  //     },
  //   ],
  // },
  // {
  //   label: t('Farms'),
  //   href: '/farms',
  //   icon: EarnIcon,
  //   fillIcon: EarnFillIcon,
  //   showItemsOnMobile: false,
  //   items: [
  //     {
  //       label: t('Farms'),
  //       href: '/farms',
  //     },
  //   ],
  // },
  // {
  //   label: t('Pools'),
  //   href: '/pools',
  //   icon: EarnIcon,
  //   fillIcon: EarnFillIcon,
  //   showItemsOnMobile: false,
  //   items: [
  //     {
  //       label: t('Pools'),
  //       href: '/pools',
  //     },
  //   ],
  // },
  // {
  //   label: t('Launchpad'),
  //   href: '#',
  //   icon: EarnIcon,
  //   fillIcon: EarnFillIcon,
  //   showItemsOnMobile: false,
  //   items: [
  //     {
  //       label: t('Launchpad'),
  //       href: '/launchpad',
  //     },
  //   ],
  // },
  // {
  //   label: t('Bridge'),
  //   href: '#',
  //   icon: EarnIcon,
  //   fillIcon: EarnFillIcon,
  //   showItemsOnMobile: false,
  //   items: [
  //     {
  //       label: t('Bridge'),
  //       href: '#',
  //     },
  //   ],
  // },
  // {
  //   label: t('Win'),
  //   href: '/prediction',
  //   icon: TrophyIcon,
  //   fillIcon: TrophyFillIcon,
  //   items: [
  // {
  //   label: t('Trading Competition'),
  //   href: '/competition',
  //   hideSubNav: true,
  // },
  // {
  //   label: t('Prediction (BETA)'),
  //   href: '/prediction',
  // },
  //     {
  //       label: t('Lottery'),
  //       href: '/lottery',
  //     },
  //   ],
  // },
  // {
  //   label: t('NFT'),
  //   href: `${nftsBaseUrl}`,
  //   icon: NftIcon,
  //   fillIcon: NftFillIcon,
  //   items: [
  //     {
  //       label: t('Overview'),
  //       href: `${nftsBaseUrl}`,
  //     },
  //     {
  //       label: t('Collections'),
  //       href: `${nftsBaseUrl}/collections`,
  //     },
  //     {
  //       label: t('Activity'),
  //       href: `${nftsBaseUrl}/activity`,
  //     },
  //   ],
  // },
  // {
  //   label: '',
  //   href: '/info',
  //   icon: MoreIcon,
  //   hideSubNav: true,
  //   items: [
  //     {
  //       label: t('Info'),
  //       href: '/info',
  //     },
  //     {
  //       label: t('WAG Burn'),
  //       href: '/burn',
  //     },
  //     // {
  //     //   label: t('IFO'),
  //     //   href: '/ifo',
  //     // },
  //     // {
  //     //   label: t('Voting'),
  //     //   href: '/voting',
  //     // },
  //     // {
  //     //   type: DropdownMenuItemType.DIVIDER,
  //     // },
  //     // {
  //     //   label: t('Leaderboard'),
  //     //   href: '/teams',
  //     // },
  //     // {
  //     //   type: DropdownMenuItemType.DIVIDER,
  //     // },
  //     // {
  //     //   label: t('Blog'),
  //     //   href: 'https://medium.com/pancakeswap',
  //     //   type: DropdownMenuItemType.EXTERNAL_LINK,
  //     // },
  //     {
  //       label: 'NFT Marketplace',
  //       href: 'https://kitchenft.io',
  //       type: DropdownMenuItemType.EXTERNAL_LINK,
  //     },
  //     {
  //       label: t('Cross chain swapz'),
  //       href: 'https://cross2.swapz.app/',
  //       type: DropdownMenuItemType.EXTERNAL_LINK,
  //     },
  //     {
  //       label: t('Docs'),
  //       href: 'https://docs.wagyuswap.app/wagyuswap',
  //       type: DropdownMenuItemType.EXTERNAL_LINK,
  //     },
  //     {
  //       label: t('Github'),
  //       href: 'https://github.com/wagyuswapapp',
  //       type: DropdownMenuItemType.EXTERNAL_LINK,
  //     },
  //     {
  //       label: t('Blog'),
  //       href: 'https://medium.com/@wagyuswap.app',
  //       type: DropdownMenuItemType.EXTERNAL_LINK,
  //     },
  //     {
  //       label: t('Support'),
  //       href: 'mailto:support@wagyuswap.app',
  //       type: DropdownMenuItemType.EXTERNAL_LINK,
  //     },
  //     {
  //       label: t('Velascan'),
  //       href: 'https://velascan.org/',
  //       type: DropdownMenuItemType.EXTERNAL_LINK,
  //     },
  //   ],
  // },
  {
    label: t('Homepage'),
    href: 'https://abstradex.xyz/',
    // icon: HomePageIcon,
  },
  {
    label: t('Swap'),
    href: '/swap',
    // icon: SwapPageIcon,
  },
  {
    label: t('Liquidity'),
    href: '/liquidity',
    // icon: LiquidityPageIcon,
  },
  {
    label: t('Yield Farming'),
    href: '/farms',
    // icon: FarmingPageIcon,
  },
  // {
  //   label: t('Bridge'),
  //   href: '/bridge',
  //   // icon: LiquidityPageIcon,
  // },
  {
    label: t('Staking'),
    href: '/pools',
    // icon: StakingPageIcon,
  },
  // {
  //   label: t('🚀 Launch'),
  //   href: '/launchpad',
  //   // icon: LaunchpadPageIcon,
  // },
  // {
  //   label: t('Cross-swap'),
  //   href: '/cross-swap',
  //   // icon: LaunchpadPageIcon,
  // },
  // {
  //   label: t('IPass'),
  //   href: '/ipass',
  //   // icon: LaunchpadPageIcon,
  // },
  // {
  //   label: t('Airdrop'),
  //   href: '#',
  //   // icon: LaunchpadPageIcon,
  // },
]

export default config
