import { ReactElement, FC, useState, Fragment, useRef } from 'react'
import { Flex, Text, Box, useOnClickOutside } from 'packages/uikit'
import styled from 'styled-components'
import { FlexGap } from 'components/Layout/Flex'
import { NextLinkFromReactRouter } from 'components/NextLink'
import { useTranslation } from 'contexts/Localization'
import { AbstradexButton } from '../components/ui'

type SelectItemProps = {
  icon?: null | unknown | string | ReactElement | boolean
  textColor?: string
  label?: string
}

const CHAINS = [
  {
    name: 'Zetachain',
    href: 'https://exchange.abstradex.xyz/',
    icon: '/images/abstradex/svgs/zetachain-icon.svg',
  },
  {
    name: 'Zkfair',
    href: 'https://zkf.abstradex.xyz/',
    icon: '/images/chain/zkfair-icon.svg',
  },
  {
    name: 'X Layer',
    href: 'https://xlayer.abstradex.xyz/',
    icon: '/images/chain/x1-icon.svg',
  },
  {
    name: 'Blast Mainnet',
    href: 'https://blast.abstradex.xyz/',
    icon: '/images/chain/blast.svg',
  },
  {
    name: 'Redstone',
    href: 'https://redstone.abstradex.xyz/',
    icon: '/images/chain/redstone.svg',
  },
  {
    name: 'Cyber',
    href: 'https://cyber.abstradex.xyz/',
    icon: '/images/chain/cyber_400x400.jpg',
  },
  {
    name: 'Taiko Hekla',
    href: 'https://taiko.abstradex.xyz/',
    icon: '/images/chain/taiko.svg',
  },
  {
    name: 'Optopia',
    href: 'https://optopia.abstradex.xyz/',
    icon: '/images/chain/optopia.jpg',
  },
  {
    name: 'Swan Saturn',
    href: 'https://swanchain.abstradex.xyz/',
    icon: '/images/chain/swanchain-icon.png',
  },
  {
    name: 'Morph L2',
    href: 'https://morphl2.abstradex.xyz/',
    icon: '/images/chain/morph-icon.jpg',
  },
  {
    name: 'BomeChain',
    href: 'https://bome.abstradex.xyz/',
    icon: '/images/chain/bome-icon.png',
  },
  {
    name: 'Aleph Zero',
    href: 'https://alephzero.abstradex.xyz/',
    icon: '/images/chain/azero.png',
  },
  {
    name: 'Cronos zkEVM',
    href: '/',
    icon: '/images/chain/cronos.png',
  },
]

const SelectChainItem: FC<SelectItemProps> = ({ icon, textColor, label }) => {
  return (
    <>
      <StyledSelectChain gap="8px" alignItems="center">
        {icon ? <img src={`${icon || '/images/chain/bome-icon.png'}`} alt="chain-icon" /> : null}
        <Text fontSize="clamp(10px, 2vw, 16px)" color={textColor || 'absWhiteColor'}>
          {label}
        </Text>
      </StyledSelectChain>
    </>
  )
}

const SelectChain = () => {
  const [openChain, setOpenChain] = useState(false)
  const { t } = useTranslation()
  const ref = useRef()

  useOnClickOutside(
    {
      current: ref.current,
    },
    () => {
      setOpenChain(false)
    },
  )

  return (
    <Box position="relative" ref={ref}>
      <DropdownButton onClick={() => setOpenChain((prev) => !prev)}>
        <SelectChainItem icon="/images/chain/cronos.png" label="Cronos zkEVM" />
      </DropdownButton>
      <DropdownBox open={openChain} minWidth="195px" maxHeight="350px" overflowY="auto" overflowX="hidden">
        <Text fontSize="12px" color="absBlackColor" textAlign="left" mb="12px">
          {t('Switch network')}
        </Text>
        <Flex flexDirection="column">
          {CHAINS.map((chain) => (
            <Fragment key={chain.name}>
              <NextLinkFromReactRouter
                to={chain.href}
                rel="noopener noreferrer"
                style={{ marginBottom: '0', textDecoration: 'none' }}
              >
                <SelectChainItem icon={chain.icon} label={chain.name} textColor="absBlackColor" />
              </NextLinkFromReactRouter>
            </Fragment>
          ))}
        </Flex>
      </DropdownBox>
    </Box>
  )
}

export default SelectChain

const DropdownBox = styled(Box)`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;

  background: ${({ theme }) => theme.colors.absModalBg};
  border-radius: 8px;
  padding: 12px 16px;

  transform: translateY(-50%);
  opacity: 0;
  transition: transform 0.25s cubic-bezier(0.075, 0.82, 0.165, 1);

  ${({ open }: { open: boolean }) =>
    open &&
    `
      transform: translateY(calc(100% + 25px));
      opacity: 1;
  `}

  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.absMainColor};
    border-radius: 8px;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px ${({ theme }) => theme.colors.input};
    border-radius: 10px;
  }
`

const DropdownButton = styled(AbstradexButton.AbstradexStyledActionButton)`
  background: #1a1e21;
  color: ${({ theme }) => theme.colors.absWhiteColor};
  min-height: 28px;
  max-height: 29px !important;
  min-width: 95px;
  position: relative;

  ${({ theme }) => theme.mediaQueries.md} {
    min-height: 44px;
    min-width: 175px;
  }
`

const StyledSelectChain = styled(FlexGap)`
  cursor: pointer;
  transform: scale(1);
  transition: all 0.35s cubic-bezier(0.175, 0.885, 0.32, 1.275);

  &:hover {
    transform: scale(1.07);
  }

  img {
    width: clamp(18px, 3vw, 24px);
    border-radius: 50%;
  }
`
